import {
  MemberBulkButtonComponents,
  MemberTableBulkName,
} from "@components/common/faro-table/faro-table-types";
import { BulkSendEmail } from "@components/table/members/bulk-actions/bulk-send-email";
import { useAppSelector } from "@store/store-helper";
import { selectedEntitiesSelector } from "@store/table/table-selector";
import { BulkResendInvitation } from "@components/table/members/bulk-actions/bulk-resend-invitation";
import { BulkRemoveMember } from "@components/table/members/bulk-actions/bulk-remove-member";
import { BulkChangeProjectRole } from "@components/table/members/bulk-actions/bulk-change-project-role";
import { BulkMemberCsvExport } from "@components/table/members/bulk-actions/bulk-member-csv-export";
import { DASH } from "@utils/ui-utils";
import { BaseMembersProps } from "@custom-types/member-types";
import { BaseCompanyIdProps } from "@custom-types/sdb-company-types";
import { SdbProject } from "@custom-types/project-types";
import { useShouldShowBulkAction } from "@hooks/table/use-should-show-bulk-action";
import { isMemberActive } from "@utils/user-utils";
import { useMemo } from "react";
import { useHasUserValidRoleProjectLevel } from "@hooks/access-control/use-has-user-valid-role-project-level";
import { useHasUserValidPermissionProjectLevel } from "@hooks/permission-control/use-has-user-valid-permission-project-level";
import { isTeamEmail } from "@utils/team-utils";

interface Props extends Partial<BaseCompanyIdProps>, BaseMembersProps {
  /** The selected project if there is one */
  project?: SdbProject | null;
}

/** List all the bulk action components for project team table */
export function ProjectTeamBulkActions({
  companyId,
  members,
  project,
}: Props): MemberBulkButtonComponents {
  const selectedEntities = useAppSelector(selectedEntitiesSelector("members"));

  const isAnyTeamMember = selectedEntities.some((member) =>
    isTeamEmail(member.email)
  );
  const isAnyRowSelected = selectedEntities.length !== 0;
  const isAnyMemberPending = selectedEntities.some(
    (member) => !isMemberActive(member)
  );
  const { canDeleteMemberFromProject } = useHasUserValidRoleProjectLevel({
    selectedProject: project,
  });

  const { canChangeRole } = useHasUserValidPermissionProjectLevel({
    project,
  });

  const allowedButtonsBasedOnRowSelection: MemberTableBulkName[] =
    useMemo(() => {
      const allowedButtons: MemberTableBulkName[] = ["exportCsv"];

      if (project && isAnyRowSelected && !isAnyTeamMember) {
        allowedButtons.push("sendEmail");

        if (canChangeRole) {
          allowedButtons.push("changeRole");
        }

        if (isAnyMemberPending) {
          allowedButtons.push("resendInvitation");
        }

        if (canDeleteMemberFromProject) {
          allowedButtons.push("removeMember");
        }
      }

      return allowedButtons;
    }, [
      canChangeRole,
      canDeleteMemberFromProject,
      isAnyMemberPending,
      isAnyRowSelected,
      isAnyTeamMember,
      project,
    ]);

  const { shouldShowBulkButtons } = useShouldShowBulkAction({
    allowedButtonsBasedOnRowSelection,
  });

  if (!companyId || !project) {
    return {
      changeRole: null,
      resendInvitation: null,
      removeMember: null,
      sendEmail: null,
      exportCsv: null,
    };
  }

  return {
    changeRole: shouldShowBulkButtons("changeRole") ? (
      <BulkChangeProjectRole companyId={companyId} projectId={project.id} />
    ) : null,

    resendInvitation: shouldShowBulkButtons("resendInvitation") ? (
      <BulkResendInvitation
        selectedEntities={selectedEntities}
        companyId={companyId}
        projectId={project.id}
        subjectType="project"
      />
    ) : null,

    removeMember: shouldShowBulkButtons("removeMember") ? (
      <BulkRemoveMember
        subjectType="project"
        companyId={companyId}
        projectId={project.id}
      />
    ) : null,

    sendEmail: shouldShowBulkButtons("sendEmail") ? (
      <BulkSendEmail selectedEntities={selectedEntities} />
    ) : null,

    exportCsv: shouldShowBulkButtons("exportCsv") ? (
      <BulkMemberCsvExport
        selectedEntities={isAnyRowSelected ? selectedEntities : members}
        subjectType="project"
        entityName={project.name ?? DASH}
        areEntitiesSelected={isAnyRowSelected}
      />
    ) : null,
  };
}
